<template>
    <div class="m-cloud-cost-stat-view">
        <div class="ctrl-bar" @click="isQueryPopupShow = true">
            <Icon name="bars" style="margin-right: 2vw"></Icon>
            <div class="label">云商：</div>
            <div class="value">{{ cloudText }}</div>
            <div class="label">周期：</div>
            <div class="value">{{ periodText }}</div>
        </div>
        <Popup
            v-model:show="isQueryPopupShow"
            position="top"
            @close="onQueryChange"
        >
            <div class="query-popup-content">
                <div class="title">查询设置</div>
                <Field
                    v-model="cloudText"
                    label="云商"
                    is-link
                    readonly
                    @click="isCloudPickerShow = true"
                ></Field>
                <Field
                    v-model="periodText"
                    label="周期"
                    is-link
                    readonly
                    @click="isPeriodPickerShow = true"
                ></Field>
            </div>
        </Popup>
        <!-- 云商选择器 -->
        <Popup v-model:show="isCloudPickerShow" round position="bottom">
            <Picker
                v-model="cloud"
                :columns="cloudOpts"
                :show-toolbar="false"
            ></Picker>
        </Popup>
        <!-- 周期选择器 -->
        <Popup v-model:show="isPeriodPickerShow" round position="bottom">
            <Picker
                v-model="period"
                :columns="periodOpts"
                :show-toolbar="false"
            ></Picker>
        </Popup>
        <div v-if="originData" class="content">
            <div class="summary">
                <div class="item">
                    <div class="key">年度低消额度</div>
                    <div class="value">
                        ${{
                            originData.cloud_target &&
                            formatNumberWithCommas(
                                originData.cloud_target.toFixed(2)
                            )
                        }}
                    </div>
                </div>
                <div class="item">
                    <div class="key">本年度完成额</div>
                    <div class="value">
                        ${{
                            originData.sum_cloud_cost &&
                            formatNumberWithCommas(
                                originData.sum_cloud_cost.toFixed(2)
                            )
                        }}
                    </div>
                </div>
                <div class="item">
                    <div class="key">本年度完成率</div>
                    <div class="value">
                        {{
                            originData.sum_cloud_rate &&
                            (originData.sum_cloud_rate * 100).toFixed(2)
                        }}%
                    </div>
                </div>
            </div>
            <div v-if="detailList.length > 0" class="detail">
                <div class="title">月度数据</div>
                <div class="list">
                    <div v-for="item in detailList" class="item">
                        <div class="date">
                            {{ `${item.year}-${item.month}` }}
                        </div>
                        <div class="cost-rate">
                            <div>
                                <span>服务器计费</span>
                                <template v-if="item.cloud_cost !== null">
                                    ${{
                                        formatNumberWithCommas(
                                            item.cloud_cost.toFixed(2)
                                        )
                                    }}
                                </template>
                                <template v-else>-</template>
                            </div>
                            <div>
                                <span>实付</span>
                                <template v-if="item.real_cost !== null">
                                    ${{
                                        formatNumberWithCommas(
                                            item.real_cost.toFixed(2)
                                        )
                                    }}
                                </template>
                                <template v-else>-</template>
                            </div>
                        </div>
                        <div class="cost-rate">
                            <div>
                                <span>优惠</span>
                                <template v-if="item.cloud_credit !== null">
                                    ${{
                                        formatNumberWithCommas(
                                            Math.abs(item.cloud_credit).toFixed(
                                                2
                                            )
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </div>
                            <div>
                                <span>完成率</span>
                                <template v-if="item.cloud_rate !== null">
                                    {{ (item.cloud_rate * 100).toFixed(2) }}%
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';

.m-cloud-cost-stat-view {
    font-size: 4vw;
    .ctrl-bar {
        margin: 0;
        padding: 2vw;
        background-color: @primary-color;
        color: #fff;

        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
            font-weight: bold;
            font-size: 3.8vw;
        }

        .value {
            margin-right: 4vw;
            font-size: 3.8vw;
        }
    }

    .query-popup-content {
        background-color: #f7f7f7;
        padding: 1vw 2vw 0vw;
        height: 20vh;
        overflow-y: auto;

        .title {
            font-size: 4vw;
            font-weight: bold;
            padding: 3vw 1vw;
            color: #333;
        }
    }

    .content {
        min-height: 90vh;
        padding-bottom: 10vw;
        .summary {
            padding: 0 2vw;
            margin-top: 3vw;
            .item {
                .standard-border;
                .standard-shadow;
                background-color: #fff;
                margin-bottom: 3vw;
                padding: 1vw 2vw;

                .key {
                    font-weight: bold;
                }

                .value {
                    font-size: 6vw;
                    line-height: 8vw;
                    color: @primary-color;
                }
            }
        }

        .detail {
            .title {
                font-weight: bold;
                padding: 0 2vw;
                font-size: 4.2vw;
                color: #333;
            }

            .list {
                margin-top: 2vw;
                background-color: #fff;
                .standard-shadow;

                .item {
                    border-bottom: 0.5px solid #eee;
                    padding: 1.6vw 2.5vw 1vw;

                    &:last-child {
                        border-bottom: none;
                    }

                    .date {
                        color: #333;
                        font-size: 3.6vw;
                        line-height: 4vw;
                        font-style: italic;
                        font-weight: bold;
                    }

                    .cost-rate {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        color: @primary-color;
                        font-size: 4vw;

                        span {
                            font-size: 2.8vw;
                            color: #888;
                            margin-right: 1vw;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}
</style>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Popup, Field, Picker, Icon, showLoadingToast, closeToast } from 'vant';
import dayjs from 'dayjs';

import { FinancialAPI } from '@/common/API';
import { formatNumberWithCommas } from '@/common/tools';

const route = useRoute();
const router = useRouter();

let isQueryPopupShow = ref(false);

function onQueryChange() {
    router.replace({
        query: { cloud: cloud.value[0], period: period.value[0] },
    });
    loadData();
}

let mapOrigin = ref(null);

function loadPeriods() {
    FinancialAPI.getCloudCostPeriodList().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        mapOrigin.value = res.data;
    });
}

loadPeriods();

let isCloudPickerShow = ref(false);

let cloudOpts = computed(() => {
    if (!mapOrigin.value) {
        return [];
    }
    return Object.keys(mapOrigin.value).map(key => ({
        text: key,
        value: key,
    }));
});

let cloud = ref(['aws']);

let cloudText = computed(() => {
    return cloud.value[0];
});

let isPeriodPickerShow = ref(false);

let periodOpts = computed(() => {
    if (!mapOrigin.value) {
        return [];
    }
    let list = mapOrigin.value[cloud.value[0]] || [];
    return list.map(item => ({
        text: `${item.period}(${item.start_date} ~ ${item.end_date})`,
        value: item.period,
    }));
});

let period = ref(['Y1']);

let periodText = computed(() => {
    let periodObj = periodOpts.value.find(
        item => item.value === period.value[0]
    );
    return periodObj ? periodObj.text : '';
});

function genEmptyDetails(startDate, count) {
    let sDate = dayjs(startDate);
    let list = [];
    for (let i = 0; i < count; i++) {
        if (count !== 12 || i !== 0) {
            sDate = sDate.add(1, 'month');
        }
        list.push({
            year: sDate.year(),
            month: sDate.month() + 1,
            cloud_cost: null,
            cloud_credit: null,
            real_cost: null,
            cloud_rate: null,
        });
    }
    return list;
}

let originData = ref(null);

let detailList = computed(() => {
    if (!originData.value) {
        return [];
    }
    let originDetails = originData.value.detail || [];
    if (originDetails.length === 0) {
        function getPeriodStartDate() {
            if (!mapOrigin.value) {
                return '2023-10-01';
            }
            return mapOrigin.value[cloud.value[0]].find(
                item => item.period === period.value[0]
            ).start_date;
        }
        return genEmptyDetails(getPeriodStartDate(), 12);
    }
    let last = originDetails[originDetails.length - 1];
    return [
        ...originDetails,
        ...genEmptyDetails(
            `${last.year}-${last.month}-01`,
            12 - originDetails.length
        ),
    ];
});

function loadData() {
    showLoadingToast({ message: '加载中...', duration: 0 });
    FinancialAPI.getCloudCostStat(cloud.value[0], period.value[0])
        .then(res => {
            closeToast();
            if (res.error_no !== 0) {
                return;
            }
            originData.value = res.data || {};
        })
        .catch(err => {
            console.log(err);
            closeToast();
        });
}

onMounted(() => {
    if (route.query.cloud) {
        cloud.value = [route.query.cloud];
    }
    if (route.query.period) {
        period.value = [route.query.period];
    }
    loadData();
});
</script>
